import {
  createStyles,
  Group,
  Highlight,
  HighlightProps,
  Stack,
} from '@mantine/core';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useKey } from 'react-use';

import { EmptySearchResults } from '@portals/core';
import { ReactComponent as TickCircleIcon } from '@portals/icons/linear/tick-circle.svg';
import { switchTenant } from '@portals/redux/actions/auth';

import { useAppConfig } from '../../context';
import { useCommonConfig } from '../../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../../hooks/users';

interface TenantsListProps {
  deferredSearchTerm: string;
}

export const TenantsList = React.memo<TenantsListProps>(
  ({ deferredSearchTerm }) => {
    const { classes, cx } = useStyles();

    const dispatch = useDispatch();

    const tenantElementRefs = useRef<Record<string, HTMLDivElement>>({});

    const { tenantType } = useAppConfig();
    const config = useCommonConfig();
    const selectedTenantId = config.data?.[tenantType]?.id;

    const [hoveredTenantId, setHoveredTenantId] = useState('');

    const accessibleTenants = useCurrentUserAccessibleTenants();

    const switchToSelectedTenant = (newTenantId: string) => {
      if (!tenantType || !accessibleTenants) return;

      if (newTenantId === selectedTenantId) {
        return;
      }

      const selectedTenant = accessibleTenants.find(
        (tenant) => tenant.id === newTenantId
      );

      if (!selectedTenant) return;

      dispatch(switchTenant(selectedTenant, tenantType, '/'));
    };

    const scrollTenantElementIntoView = (tenantId: string) => {
      const element = tenantElementRefs.current[tenantId];

      if (element) {
        element.scrollIntoView({ block: 'nearest' });
      }
    };

    const filteredTenants = useMemo(() => {
      // Reset the `hoveredTenantId` whenever the filtered list changes,
      // to avoid selecting a hovered tenant which is not visible by clicking `Enter`
      setHoveredTenantId('');

      if (!accessibleTenants) return [];

      if (deferredSearchTerm === '') return accessibleTenants;

      const lowerCasedSearchTerm = deferredSearchTerm.toLowerCase();

      return accessibleTenants.filter((tenant) => {
        const searchableContent = `${tenant.name?.toLowerCase()}__@@__${tenant.display_name?.toLowerCase()}`;

        return searchableContent.includes(lowerCasedSearchTerm);
      });
    }, [accessibleTenants, deferredSearchTerm]);

    const onArrowDown = () => {
      // Clear the `hoveredTenantId` if the tenants list is empty
      if (filteredTenants.length === 0) {
        setHoveredTenantId('');
        return;
      }

      let tenantIdToHover: string;

      // If the `hoveredTenantId` is not set yet, set it to the first tenant in the list
      if (!hoveredTenantId) {
        tenantIdToHover = filteredTenants[0].id;

        setHoveredTenantId(tenantIdToHover);
        scrollTenantElementIntoView(tenantIdToHover);

        return;
      }

      const currentHoveredTenantIndex = filteredTenants.findIndex(
        (tenant) => tenant.id === hoveredTenantId
      );

      if (
        currentHoveredTenantIndex === -1 ||
        currentHoveredTenantIndex === filteredTenants.length - 1
      ) {
        // Set the `hoveredTenantId` to the first tenant in the list
        tenantIdToHover = filteredTenants[0].id;
      } else {
        // Set the `hoveredTenantId` to the next tenant in the list
        tenantIdToHover = filteredTenants[currentHoveredTenantIndex + 1].id;
      }

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);
    };

    const onArrowUp = () => {
      // Clear the `hoveredTenantId` if the tenants list is empty
      if (filteredTenants.length === 0) {
        setHoveredTenantId('');
        return;
      }

      let tenantIdToHover: string;

      // If the `hoveredTenantId` is not set yet, set it to the last tenant in the list
      if (!hoveredTenantId) {
        tenantIdToHover = filteredTenants[filteredTenants.length - 1].id;

        setHoveredTenantId(tenantIdToHover);
        scrollTenantElementIntoView(tenantIdToHover);

        return;
      }

      const currentHoveredTenantIndex = filteredTenants.findIndex(
        (tenant) => tenant.id === hoveredTenantId
      );

      if (currentHoveredTenantIndex === -1 || currentHoveredTenantIndex === 0) {
        tenantIdToHover = filteredTenants[filteredTenants.length - 1].id;
      } else {
        // Set the `hoveredTenantId` to the prev tenant in the list
        tenantIdToHover = filteredTenants[currentHoveredTenantIndex - 1].id;
      }

      setHoveredTenantId(tenantIdToHover);
      scrollTenantElementIntoView(tenantIdToHover);
    };

    const onEnter = () => {
      if (!hoveredTenantId) return;

      switchToSelectedTenant(hoveredTenantId);
    };

    useKey('ArrowDown', onArrowDown, {}, [filteredTenants, hoveredTenantId]);
    useKey('ArrowUp', onArrowUp, {}, [filteredTenants, hoveredTenantId]);
    useKey('Enter', onEnter, {}, [hoveredTenantId]);

    if (filteredTenants.length === 0) {
      return (
        <Stack className={classes.listContainer}>
          <EmptySearchResults searchValue={deferredSearchTerm} />
        </Stack>
      );
    }

    return (
      <Stack spacing="xs" className={classes.listContainer}>
        {filteredTenants.map((tenant) => {
          const isSelected = selectedTenantId === tenant.id;

          return (
            <Group
              noWrap
              position="apart"
              key={tenant.id}
              data-testid={`tenant-${tenant.display_name}`}
              onClick={() => switchToSelectedTenant(tenant.id)}
              ref={(el: HTMLDivElement) =>
                (tenantElementRefs.current[tenant.id] = el)
              }
              className={cx(classes.item, {
                [classes.hoveredItem]: hoveredTenantId === tenant.id,
                [classes.selectedItem]: isSelected,
              })}
            >
              <Stack spacing={4}>
                <Highlight
                  size="sm"
                  highlight={deferredSearchTerm}
                  highlightStyles={highlightStyles}
                >
                  {tenant.display_name}
                </Highlight>

                <Highlight
                  size="xs"
                  color="dimmed"
                  highlight={deferredSearchTerm}
                  highlightStyles={highlightStyles}
                >
                  {tenant.name}
                </Highlight>
              </Stack>

              {isSelected && <TickCircleIcon />}
            </Group>
          );
        })}
      </Stack>
    );
  }
);

const highlightStyles: HighlightProps['highlightStyles'] = {
  padding: 0,
};

const useStyles = createStyles((theme) => ({
  listContainer: {
    position: 'relative',
    overflow: 'auto',
    height: '100%',
    paddingInline: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },
  item: {
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
    borderRadius: theme.radius.md,
    border: `1px solid transparent`,
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },
  selectedItem: {
    '&, &:hover': {
      backgroundColor: theme.colors.blue[0],
    },
  },
}));
