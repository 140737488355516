import {
  createStyles,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useDeferredValue, useState } from 'react';

import { SearchInput } from '@portals/core';

import { TenantsList } from './TenantsList';

import type { ModalProps } from '../../components/Modals';

export function SwitchTenantModal({ closeMe }: ModalProps) {
  const { classes } = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);
  const isStale = searchTerm !== deferredSearchTerm;

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={0}
      size={350}
      mah={400}
      radius="md"
      styles={modalStyles}
      title="Switch Tenant"
    >
      <div className={classes.contentContainer}>
        <SearchInput
          autoFocus
          data-autofocus
          size="sm"
          value={searchTerm}
          placeholder="Find tenant..."
          className={classes.input}
          loading={isStale}
          data-testid="tenant-switch-search-input"
          onChange={(event) => setSearchTerm(event.target.value)}
          onClear={() => setSearchTerm('')}
        />

        <TenantsList deferredSearchTerm={deferredSearchTerm} />
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    height: 500,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  body: {
    overflow: 'hidden',
  },
});

const useStyles = createStyles((theme) => ({
  input: {
    width: 'auto',
    marginInline: theme.spacing.sm,
  },
  contentContainer: {
    overflow: 'hidden',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gap: theme.spacing.md,
    paddingTop: theme.spacing.md,
  },
}));
